import { useField, useFormikContext } from 'formik'
import { Ref, forwardRef } from 'react'
import ReactTextarea from 'react-textarea-autosize'

import { Field, FieldProps } from '../field'
import { Meta } from '../typography'
import { StyledTextarea } from './style'

type TextareaProps = {
  value: string
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void
  autoFocus?: boolean
  disabled?: boolean
  minRows?: number
  maxLength?: number
}

type Props = FieldProps & TextareaProps

const Textarea = (props: Props, ref: Ref<HTMLTextAreaElement>) => {
  const {
    name,
    value,
    onChange,
    onBlur,
    autoFocus,
    onKeyDown,
    minRows,
    maxLength,
    disabled,
    ...field
  } = props

  return (
    <Field name={name} {...field}>
      {maxLength && (
        <Meta regular style={{ position: 'absolute', top: 0, right: 0 }}>
          {value.length}/{maxLength}
        </Meta>
      )}
      <StyledTextarea
        as={ReactTextarea}
        ref={ref}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus}
        minRows={minRows}
        maxLength={maxLength}
        aria-invalid={!!field.error}
        disabled={disabled}
      />
    </Field>
  )
}

const _Textarea = forwardRef(Textarea)
export { _Textarea as Textarea }

type TextareaFieldProps = Omit<Props, 'value' | 'onChange' | 'onBlur'> & {
  name: string
}

export const TextareaField = ({ name, ...rest }: TextareaFieldProps) => {
  const [field, meta] = useField<string>({ name })
  const formik = useFormikContext()

  return (
    <_Textarea
      error={meta.touched && meta.error ? meta.error : undefined}
      disabled={formik.isSubmitting}
      {...field}
      {...rest}
    />
  )
}
