import type { Values } from './hooks/use-form'
export const addProcessingFees = (
  amount: number,
  fee: number,
  fixed: number = 30
): number => {
  return Math.round(fee * amount + fixed + amount)
}

export const getDoubleTheDonationValues = () => {
  const data = JSON.parse(localStorage.doublethedonation || '{}') as {
    doublethedonation_entered_text?: string
    doublethedonation_status?: string
    doublethedonation_company_id?: number
  }

  return {
    doubleTheDonationEnteredText: data.doublethedonation_entered_text,
    doubleTheDonationStatus: data.doublethedonation_status,
    doubleTheDonationCompanyId: data.doublethedonation_company_id,
  }
}

type RegisterDoubleTheDonationInput = {
  publicKey: string
  orderId: string
  pageId: string
  values: Values
  amount: number
}

// DOCS: https://doublethedonation.com/api/360matchpro/setup/custom_implementation.html#schema
export const registerDoubleTheDonation = ({
  publicKey,
  pageId,
  orderId,
  values,
  amount,
}: RegisterDoubleTheDonationInput) => {
  if ((window as any).doublethedonation) {
    const {
      doubleTheDonationCompanyId,
      doubleTheDonationEnteredText,
      doubleTheDonationStatus,
    } = getDoubleTheDonationValues()

    ;(window as any).doublethedonation.integrations.core.register_donation({
      '360matchpro_public_key': publicKey,
      partner_identifier: 'KINDE-AmSgVu2wWebIYZuy',
      campaign: pageId,
      donation_identifier: orderId,
      donation_amount: amount / 100,
      donor_first_name: values.firstName,
      donor_last_name: values.lastName,
      donor_email: values.email,
      doublethedonation_company_id: doubleTheDonationCompanyId,
      doublethedonation_entered_text: doubleTheDonationEnteredText,
      doublethedonation_status: doubleTheDonationStatus,
    })
    ;(window as any).doublethedonation.plugin.set_donation_identifier(orderId)
    ;(window as any).doublethedonation.plugin.set_donation_campaign(pageId)
    if (doubleTheDonationCompanyId) {
      ;(window as any).doublethedonation.plugin.set_company(
        doubleTheDonationCompanyId
      )
    } else {
      const domain = (
        window as any
      ).doublethedonation.integrations.core.strip_domain(values.email)
      ;(window as any).doublethedonation.plugin.email_domain(domain)
    }
  }
}
