import { useEffect, useRef } from 'react';
import useScript from 'react-script-hook';
import { env } from '~/config/env';

export const useRecaptcha = () => {
  const callbackRef = useRef<ReCaptchaV2.Parameters['callback']>()
  const errorCallback = useRef<ReCaptchaV2.Parameters['error-callback']>()
  const expiredCallback = useRef<ReCaptchaV2.Parameters['expired-callback']>()

  useEffect(() => {
    const div = document.createElement('div')
    div.id = 'recaptcha-container'
    div.className = 'g-recaptcha'
    div.style.visibility = 'hidden'
    document.body.appendChild(div)

    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        renderElement()
      })
    }

    return () => {
      if (window.grecaptcha?.enterprise) {
        window.grecaptcha.reset()
      }
      document.body.removeChild(div)
    }
  }, [])

  const renderElement = () => {
    window.grecaptcha.render(
      document.getElementById('recaptcha-container') as HTMLDivElement,
      {
        sitekey: env.recaptchaSiteKey,
        size: 'invisible',
        callback: token => callbackRef.current?.(token),
        'expired-callback': () => expiredCallback.current?.(),
        'error-callback': () => errorCallback.current?.(),
      }
    )
  }

  useScript({
    src: `https://www.google.com/recaptcha/api.js?render=explicit`,
    checkForExisting: true,
    async: true,
    defer: true,
    onload: () =>
      (window as any).grecaptcha.ready(() => {
        renderElement()
      }),
  })

  const execute = async () => {
    return await new Promise<string>((resolve, reject) => {
      callbackRef.current = resolve
      errorCallback.current = () => reject(new Error('ReCaptcha failed'))
      expiredCallback.current = () => reject(new Error('ReCaptcha expired'))

      const res = window.grecaptcha.getResponse()
      if (res) {
        resolve(res)
      } else {
        window.grecaptcha.execute()
      }
    })
  }

  const reset = () => {
    window.grecaptcha.reset()
  }

  return { execute, reset } as const
}
