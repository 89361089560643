import {
  Radio as ReakitRadio,
  RadioGroup as ReakitRadioGroup,
} from 'reakit/Radio'
import styled from 'styled-components'
import { colors } from '~/styles/colors'

export const RadioGroup = styled(ReakitRadioGroup)({
  display: 'flex',
})

export const Label = styled.label({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.875rem',
  lineHeight: 1.7,
  color: colors.dark,
  cursor: 'pointer',
  marginRight: 30,
})

export const RadioButton = styled(ReakitRadio)({
  background: colors.white,
  border: colors.border,
  borderRadius: '100%',
  minWidth: 22,
  minHeight: 22,
  marginRight: 10,

  '&:hover': {
    borderColor: colors.slateGray,
  },

  '&:checked': {
    background: colors.primary.default,
    borderColor: colors.primary.default,
    boxShadow: `0 0 0 3px ${colors.white} inset`,
  },
})
