import styled from 'styled-components'
import { colors } from '~/styles/colors'
import { fonts } from '~/styles/fonts'

export const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'row-reverse',

  'input&:hover + div': {
    borderColor: colors.slateGray,
  },

  'input:focus + div': {
    background: colors.dark,
    color: colors.white,
    borderColor: colors.dark,
  },

  "input[aria-invalid='true'] + div": {
    borderWidth: 2,
    borderColor: colors.error,
  },
})

export const Prefix = styled.div({
  fontSize: '0.875rem',
  fontWeight: fonts.text.medium,
  width: 40,
  color: colors.dark,
  background: colors.soft,
  border: colors.border,
  borderRight: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '2px 0 0 2px',
})
