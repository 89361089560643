import { Checkbox as ReakitCheckbox } from 'reakit/Checkbox'
import styled from 'styled-components'
import { colors } from '~/styles/colors'

export const Label = styled.label({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.875rem',
  lineHeight: 1.7,
  color: colors.dark,
  cursor: 'pointer',

  svg: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 5,
  },
})

export const Checkmark = styled(ReakitCheckbox)({
  cursor: 'pointer',
  appearance: 'none',
  background: colors.white,
  borderRadius: 2,
  border: colors.border,
  outline: 'none',
  width: 22,
  height: 22,
  minWidth: 22,
  minHeight: 22,
  marginRight: 14,

  '&:hover': {
    borderColor: colors.slateGray,
  },

  '&:checked': {
    background: colors.primary.default,
    borderColor: colors.primary.default,
  },

  '&[data-invalid="true"]': {
    borderColor: colors.error,
  },
})
