import {
  DonationRecurrenceInterval,
  OrderCalculationResult,
} from '~/types/graphql'

export enum PaymentMethod {
  Card = 'card',
  Ach = 'ach',
  Chariot = 'chariot',
  Other = 'other',
}

export type PaymentOrder = {
  amount: number
  coverProcessingFees: boolean
  orderCalculation: {
    withCoveringFees: OrderCalculationResult
    withoutCoveringFees: OrderCalculationResult
    tip: number
  }
  paymentFrequency: DonationRecurrenceInterval
}
