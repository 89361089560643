import { Root } from '@radix-ui/react-accessible-icon'

interface Props {
  children: React.ReactNode
  label: string
}

export const AccessibleIcon = (props: Props) => {
  return <Root {...props} />
}
