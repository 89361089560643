import { useEffect } from 'react'
import { useRadioState } from 'reakit/Radio'
import { Label, RadioGroup, RadioButton } from './style'

type Props = {
  defaultValue: string
  items: { label: string; value: string }[]
  ariaLabel: string
  onChange: (value: string) => void
}

export const Radio = ({ defaultValue, items, ariaLabel, onChange }: Props) => {
  const radio = useRadioState({
    state: defaultValue,
  })

  useEffect(() => {
    onChange(radio.state as string)
  }, [radio.state])

  return (
    <RadioGroup {...radio} aria-label={ariaLabel}>
      {items.map(item => (
        <Label key={item.value}>
          <RadioButton {...radio} value={item.value} /> {item.label}
        </Label>
      ))}
    </RadioGroup>
  )
}
