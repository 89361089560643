import { useField } from 'formik'
import { FocusEventHandler, forwardRef, useEffect, useState } from 'react'
import { Rifm } from 'rifm'

import { Field, FieldProps } from '../field'
import { Input } from '../text-input/style'
import { Prefix, Wrapper } from './style'

type Props = FieldProps & {
  value: number
  onChange: (amount: number) => void
  onBlur?: FocusEventHandler<HTMLInputElement>
  placeholder?: string
  disabled?: boolean
  autoFocus?: boolean
}

// Parse head and tail values to cents
const parseInteger = (value: any) => {
  const parsed = parseNumber(value)
  const [head, tail] = parsed.split('.')
  const formatedHead = +head * 100
  const formatedTail = tail ? (tail.length == 2 ? +tail : +tail * 10) : 0

  return formatedHead + formatedTail
}

const numberAccept = /[\d.]+/g

const parseNumber = (value: string) =>
  (value.match(numberAccept) || []).join('')

const formatFloatingPointNumber = (value: string, maxDigits: number) => {
  const parsed = parseNumber(value)
  const [head, tail] = parsed.split('.')
  // Avoid rounding errors at toLocaleString as when user enters 1.239 and maxDigits=2 we
  // must not to convert it to 1.24, it must stay 1.23
  const scaledTail = tail != null ? tail.slice(0, maxDigits) : ''

  const number = Number.parseFloat(`${head}.${scaledTail}`)

  if (Number.isNaN(number)) {
    return ''
  }

  const formatted = number.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDigits,
  })

  if (parsed.includes('.')) {
    const [formattedHead] = formatted.split('.')
    // console.log(scaledTail);
    // skip zero at digits position for non fixed floats
    // as at digits 2 for non fixed floats numbers like 1.50 has no sense, just 1.5 allowed
    // but 1.0 has sense as otherwise you will not be able to enter 1.05 for example
    const formattedTail =
      scaledTail !== '' && scaledTail[maxDigits - 1] === '0'
        ? // ? scaledTail.slice(0, -2)
          scaledTail.slice(0, maxDigits)
        : scaledTail

    return `${formattedHead}.${formattedTail}`
  }
  return formatted
}

export const AmountInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      disabled,
      placeholder,
      value,
      onChange,
      onBlur,
      autoFocus,
      ...field
    },
    ref
  ) => {
    const [variableFloat, setVariableFloat] = useState((value / 100).toString())

    useEffect(() => {
      setVariableFloat((value / 100).toString())
    }, [value])

    // TODO add max value to 10000000
    return (
      <Field name={name} {...field}>
        <Wrapper>
          <Rifm
            accept={/[\d.]/g}
            format={v => formatFloatingPointNumber(v, 2)}
            value={variableFloat}
            onChange={value => {
              onChange(parseInteger(value))
              return setVariableFloat(parseNumber(value))
            }}
          >
            {({ value, onChange }) => (
              <Input
                type="tel"
                value={value}
                name={name}
                ref={ref}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={placeholder}
                autoFocus={autoFocus}
                disabled={disabled}
                autoComplete="off"
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck={false}
                aria-invalid={!!field.error}
                style={{ borderRadius: '0 2px 2px 0' }}
              />
            )}
          </Rifm>
          <Prefix>$</Prefix>
        </Wrapper>
      </Field>
    )
  }
)

type AmountInputFieldProps = Omit<Props, 'value' | 'onChange' | 'onBlur'> & {
  name: string
}

export const AmountInputField = forwardRef<
  HTMLInputElement,
  AmountInputFieldProps
>(({ name, ...rest }, ref) => {
  const [field, meta, { setValue }] = useField(name)

  return (
    <AmountInput
      ref={ref}
      {...field}
      {...rest}
      error={meta.touched && meta.error ? meta.error : undefined}
      value={field.value}
      onChange={setValue}
    />
  )
})
