import { Slot } from '@radix-ui/react-slot'
import { Content, Provider, Root, Trigger } from '@radix-ui/react-tooltip'
import { Children, ReactElement } from 'react'
import { colors } from '~/styles/colors'

interface Props {
  children: [ReactElement, ReactElement]
  delayDuration?: number
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export const Tooltip = (props: Props) => {
  const { children, delayDuration, open, onOpenChange } = props

  const [trigger, content] = Children.toArray(children)

  return (
    <Provider>
      <Root
        delayDuration={delayDuration}
        open={open}
        onOpenChange={onOpenChange}
      >
        {/* // as={Slot} */}
        <Trigger {...Slot}>{trigger}</Trigger>
        <Content
          align="start"
          sideOffset={8}
          style={{
            padding: '18px 20px',
            background: colors.dark,
            color: colors.white,
            borderRadius: 4,
          }}
        >
          {content}
        </Content>
      </Root>
    </Provider>
  )
}
