import { styled } from "styled-components";
import { Textarea } from "./textarea";

const SnippetTextareaWrapper = styled(Textarea)`
  display: block;
  width: 100%;
`;

type SnippetTextareaProps = {
  value: string;
  autoRows?: boolean;
  rows?: number;
};

export const SnippetTextarea = (props: SnippetTextareaProps) => {
  const numOfRows = props.value.split('\n').length;

  return (
    <SnippetTextareaWrapper
      disabled
      minRows={props.autoRows ? numOfRows : props.rows}
      value={props.value}
      onChange={() => {}}
    >
    </SnippetTextareaWrapper>
  )
}
