import * as React from 'react'

import { Label, Checkmark } from './style'
import { useField } from 'formik'

import IconCheckmark from './assets/icon-checkmark.svg'
import { SpaceProps } from 'styled-system'
import { Box } from '../layout'

type Props = SpaceProps & {
  name?: string
  label: string
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  error?: string
}

export const Checkbox = ({
  name,
  label,
  checked,
  onChange,
  onBlur,
  error,
  ...space
}: Props) => {
  return (
    <Box {...space}>
      <Label>
        <Checkmark
          name={name}
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          data-invalid={!!error}
        />
        <IconCheckmark />
        {label}
      </Label>
    </Box>
  )
}

type CheckboxFieldProps = SpaceProps & {
  name: string
  label: string
}

export const CheckboxField = ({
  name,
  label,
  ...space
}: CheckboxFieldProps) => {
  const [field, meta] = useField<boolean>({ name })

  return (
    <Checkbox
      label={label}
      name={field.name}
      checked={field.value}
      onChange={field.onChange}
      error={meta.touched && meta.error ? meta.error : undefined}
      {...space}
    />
  )
}
