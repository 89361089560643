import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import scrollIntoView from 'scroll-into-view-if-needed'
import { usePrevious } from '~/hooks/use-previous'

export const FocusError = () => {
  const { isSubmitting, errors } = useFormikContext()
  const prevSubmitting = usePrevious(isSubmitting)

  useEffect(() => {
    if (prevSubmitting === true && isSubmitting === false) {
      const keys = Object.keys(errors)
      if (keys.length > 0) {
        const selector = `[aria-invalid="true"]`
        const element = document.querySelector(selector) as HTMLElement
        if (element) {
          scrollIntoView(element, {
            behavior: 'smooth',
            scrollMode: 'if-needed',
          })
          element.focus()
        }
      }
    }
  }, [errors, prevSubmitting, isSubmitting])

  return null
}
